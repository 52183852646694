import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Posting - Anzeigenschaltung",
  "description": "Posting - Anzeigenschaltung",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Welche Daten müssen zur Schaltung vorliegen?`}</h2>
    <p>{`Abhängig von derJobbörse können zusätzliche Informationen benötigt werden. Das Anforderungsprofil kann wie folgt festgelegt und an die Jobbörsen übermittelt werden. Sind Informationen bereits in der Ausschreibungskarte hinterlegt worden, müssen diese nicht noch einmal eingegeben werden.`}</p>
    <img src="/images/gettingstarted/schlagworte.png" alt="KPI" style={{
      "width": "100%"
    }} />
    <h2>{`Können einmal veröffentlichte Anzeigen wieder offline geschaltet werden?`}</h2>
    <p>{`Ja. Bitte senden Sie uns eine E-Mail oder Chatnachricht mit folgende Informationen:`}</p>
    <ul>
      <li parentName="ul">{`Ihr Unternehmen `}</li>
      <li parentName="ul">{`Jobtitel der Anzeige`}</li>
      <li parentName="ul">{`order_id der Anzeige`}</li>
    </ul>
    <p>{`Wir leiten die Änderung an die Jobbörse weiter und informieren Sie, sobald Ihre Anzeige offline ist.`}</p>
    <h2>{`Wie funktioniert das Bearbeiten von Stellenanzeigen nach der Veröffentlichungen?`}</h2>
    <p>{`Zum Bearbeiten einer bereits veröffentlichten Stellenanzeige, senden Sie uns bitte eine E-Mail mit folgenden Informationen:`}</p>
    <p><em parentName="p">{`Ihr Unternehmen
`}</em>{`Jobtitel der Anzeige
`}<em parentName="p">{`order_id der Anzeige
`}</em>{`Information darüber, was genau geändert werden sol`}</p>
    <p>{`Wir leiten die Änderung an die Jobbörse weiter und informieren Sie über das Resultat. `}</p>
    <p>{`Hinweis: Für gewöhnlich können alle Jobbörsen mit kleineren Änderungen umgehen. Natürlich sollte inhaltlich keine völlig neue Stelle entstehen. Änderungen des Jobtitels oder des Einsatzortes sind bei einigen Börsen problematisch.`}</p>
    <h2>{`Was hat es mit den Kategorien auf sich?`}</h2>
    <p>{`Suchmaschinenoptimierung. Desto genauer Sie Ihre Anzeige Kategorien zuordnen, desto besser wird Ihre Anzeige in den (Freitext)-Suchen der Jobbörsen gefunden.
Hintergrund: Die Jobbörse kann anhand von Kategorien und Schlagwörtern die Relevanz der jeweiligen Stellenanzeige für die Suchanfragen ihrer Nutzer einschätzen. Je relevanter die Anzeige, desto weiter oben steht sie in der Suchergebnisliste der Jobbörse.`}</p>
    <h2>{`Wo werden die Stellenanzeigen bei den unterschiedlichen Jobbörsen angezeigt?`}</h2>
    <p>{`Unterschiedlich. Jede Jobbörse handhabt die Darstellung und Verlinkung der Stellenanzeigen anders.`}</p>
    <p>{`Tipp: In der Jobbörsen-Übersicht informieren Sie sich zu Besonderheiten der einzelnen Jobbörsen.`}</p>
    <p>{`Hinweis: Wir arbeiten daran, Besonderheiten bei der Darstellung der einzelnen Jobbörsen noch transparenter zu gestalten. `}</p>
    <h2>{`Kann es sein, dass die Jobbörse eine Buchung ablehnt?`}</h2>
    <p>{`Ja. In diesem Fall werden Sie per E-Mail informiert – und erhalten Handlungsempfehlungen, um Ihre Anzeige anzupassen.   `}</p>
    <p>{`Ein möglicher Grund: Sie möchten beispielsweise eine Vollzeitstelle bei einer Jobbörse ausschreiben, die sich ausschließlich an Studierende und Praktikanten richtet. `}</p>
    <p>{`##Wie funktioniert der Refresh von Anzeigen?
Der Refresh wird jeweils von der Jobbörse optimal ausgesteuert.`}</p>
    <p>{`Hintergrund: Bei einem Refresh wird das Datum der Stellenanzeige aktualisiert. Daraufhin erscheint die Stellenanzeige im oberen Bereich der Ergebnisliste der Jobbörse. So besteht eine bessere Chance, dass Bewerber die Stellenanzeige finden.`}</p>
    <p>{`Tipp: In den Beschreibungen der Jobbörsen können sich Nutzer zur Anzahl der Refreshs informieren. `}</p>
    <h2>{`Nach welchen Kriterien werden Jobbörsen empfohlen?`}</h2>
    <p>{`Heute: Auf Basis des Google-Rankings. Später: Auf Basis von Daten.`}</p>
    <p>{`Hintergrund: Wir möchten die Jobbörsen vorschlagen, die die besten Ergebnisse versprechen. Mittelfristig wollen wir Vorschläge auf Basis von Daten liefern. Der Algorithmus wird bei jeder Buchung prüfen, welche Jobbörsen für ähnliche Unternehmen und ähnliche Positionen in der Vergangenheit die besten Ergebnisse geliefert haben. Mit jeder Buchung wird der Algorithmus dazulernen und so immer bessere Vorschläge liefern (lernendes Vorschlagswesen). Doch dazu braucht es eine Menge Daten. Die stehen derzeit noch nicht zur Verfügung.`}</p>
    <p>{`Um Nutzern heute schon erfolgversprechende Jobbörsen vorschlagen zu können, vertrauen wir auf folgende einfache, aber effektive und faire Methode:  `}</p>
    <p>{`So funktioniert das Vorschlagswesen heute: Mit dem individuellen Anzeigentitel als Suchanfrage durchsuchen wir alle bei uns gelisteten Jobbörsen mittels Google-Algorithmus. Je weiter oben eine Jobbörse in der Ergebnisliste bei Google auftaucht, je weiter oben erscheint sie auch in unserer Vorschlagsliste. `}</p>
    <p>{`Um sicherzustellen, dass auf jeden Fall erfolgversprechende Nischen-Jobbörsen oben in den Vorschlägen zu finden sind, führen wir noch eine zweite Suche durch. Dabei durchsuchen wir ausschließlich die bei uns gelisteten Nischen-Jobbörsen. Die Ergebnisliste setzt sich aus den Ergebnissen beider Suchen zusammen.`}</p>
    <p>{`Generalisten wie Stepstone erzeugen eine Menge Traffic und werden oft verlinkt. Dementsprechend dominieren Sie die Google-Ergebnisse. Doch die durchschnittliche Qualifikation der Bewerber ist bei Nischen-Jobbörsen höher als bei Generalisten.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      